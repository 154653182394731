import React from "react"
// import { Link } from "gatsby"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Table from 'react-bootstrap/Table'
import ThermotronImage from "../components/logicom-images/ThermotronImage.js"

const Thermotron = () => (
    <Layout>
      <SEO title="Thermotron" />
      <Container>
        <Row>
            {/* <div><img src="holder.js/600x110?random=yes&auto=yes"/></div> */}
            <Col><ThermotronImage/></Col>
        </Row>
        <Row>
            <Col>
                <p>
                With over four decades of experience, Thermotron has evolved into one of the world’s premier suppliers of high-performance environmental test equipment, screening and simulation equipment, vibration test systems and functional test systems and integration. We have had the privilege of working with some of the world’s greatest companies in a range of industries, developing test equipment for a variety of testing requirements.
                </p>
                <p>
                Today, Thermotron employs the largest application, service and design engineering support staff in the industry. Using the precepts of quality to grow and continuously improve our company, we always strive to meet or exceed the expectations of our customers. Our record of achievement comes from an unwavering focus on a few simple goals: identify customer needs, produce superior testing equipment and offer comprehensive service.
                </p>
            </Col>
        </Row>
        <Row>
            <Col>
                <p>We pride ourselves on being a single-source supplier to our customers able to provide complete, sophisticated “turn-key” environmental test systems. Our core competencies include:</p>
                <Table>
                    <tr>
                        <td>- High Performance Refrigeration</td>
                        <td>- Application Specific Software</td>
                    </tr>
                    <tr>
                        <td>- Combined Environmental Testing</td>
                        <td>- Direct Sales, Product Support, and Service</td>
                    </tr>
                    <tr>
                        <td>- Vibration Test Equipment</td>
                        <td>- Cabinet Design and Fabrication</td>
                    </tr>
                    <tr>
                        <td>- PCB Design, Layout, and Production</td>
                        <td>- Customization</td>
                    </tr>
                    <tr>
                        <td>- Control System Development</td>
                        <td>- Functional Test Equipment and Integration</td>
                    </tr>
                </Table>
                <p>Thermotron is pleased to offer equipment meeting many industry standards including SAE, IEC JEDEC, ASTM and MIL-STD. We are also ISO: 9001 certified.</p>
            </Col>
        </Row>
      </Container>
      
    </Layout>
  )
  
  export default Thermotron
